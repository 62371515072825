<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.latest {
  font-weight: 700;
  /* background-color: var(--dark); */
  /* border-left: 1px solid var(--dark); */
  /* color: unset; */
  /* text-decoration: underline aliceblue; */
}
</style>

<template>
  <div class="media-list media-list-divided">
    <div
      v-for="(f, idx) in files" :key="f._id"
      class="media"
    >
      <a class="align-self-center hover-primary" href="#" @click="openfile(f)">
        <Icon icon="bxs:file-pdf" :inline="true" width="32" height="32" />
      </a>
      <div
        class="media-body"
      >
        <a
          href="#"
          class="hover-primary text-light"
          style="word-break: break-all;"
          :class="{latest: idx<1}"
          @click="openfile(f)"
        >
          <!-- <span class="title"> -->
          {{ f.name }}
        <!-- </span> -->
        </a>
        <br>
        <time class="float-sright text-dark" datetime="2017-07-14 20:00">
          {{ f.dateStr }}.
        </time>
      </div>
    </div>
  </div>
</template>

<script>
import { format, formatDistance, parse } from "date-fns";

import debounce from "lodash/debounce";
import { Icon } from "@iconify/vue2";
import QuotationsApi from "./Quotations.service";

export default {
  name: "QuotationFileList",
  components: {
    Icon
  },
  props: {
    id: {
      type: String,
      default: ""
    },
    refresh: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      files: () => []
    };
  },
  watch: {
    id: debounce(function (val, oldval) {
      QuotationsApi.getPdfsFor({ parent: val })
        .then(res => {
          this.transformRows(res.rows);
        });
    }, 200),
    refresh: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler(val, oldVal) {
        console.log("GOT Quotation links");
        QuotationsApi.getPdfsFor({ parent: this.id })
          .then(res => {
            console.log("GOT links", res);
            this.transformRows(res.rows);
          });
      }
    },
  },

  methods: {
    transformRows(origrows) {
      const rows = origrows.map(it => {
        console.log(new Date(it.createdAt));
        const dateStr = format(new Date(it.createdAt), "d MMM, p"); //= > 'Nov'
        return { ...it, dateStr };
      });
      this.files = rows;
    },
    openfile(f) {
      // console.log("Opening", f);
      QuotationsApi.getPdfDownloadLink({ parent: this.id, fileid: f._id })
        .then(res => {
          // console.log("GOT real link", res);
          // this.files = res.rows;
          window.open(res, `${f._id}`);
        });
    }
  },
  mounted() {

  },
};
</script>
